<template>
    <div v-if="editable" class="rich-editor" key="edit">
        <vue-neditor-wrap v-model="richText" :config="config"></vue-neditor-wrap>
        <div class="done">
            <p>注：富文本编辑器控件处于编辑状态时不能进行拖动排序</p>
            <button @click="editDone">编辑完成</button>
        </div>
    </div>
    <div v-else class="rich-editor" key="preview">
        <div class="pre"><div v-html="richText"></div></div>
    </div>
</template>
<script>
import VueNeditorWrap from 'vue-neditor-wrap';
// import '../../../../public/neditor/neditor.config.js';
// import '../../../../public/neditor/neditor.all.js';
// import '../../../../public/neditor/neditor.service.js';
// import registXiumi from '../../../../public/neditor/xiumi-plugin/xiumi-ue-dialog-v5.js';
import '../../../../public/neditor/xiumi-plugin/xiumi-ue-v5.css';

export default {
    name: "RichText",
    props: ["content", "editable"],
    components: {
        VueNeditorWrap
    },
    data() {
        return {
            richText: this.content,
            config: {
                autoHeightEnabled: false,
                initialFrameHeight: 300,
                initialFrameWidth: '100%',
                serverUrl: 'https://service-017grgna-1258302538.gz.apigw.tencentcs.com/test/uploadImage',
                UEDITOR_HOME_URL: '/neditor/',
            }
        }
    },
    methods: {
        editDone() {
            this.$emit("editDone");
        }
    },
    watch: {
        richText(val) {
            this.$emit('updateRichText', val);
        }
    }
}
</script>
<style lang="less">
    .done {
        overflow: hidden;

        p {
            float: left;
            height: 32px;
            line-height: 32px;
            margin: 0;
            color: #8494A6;
        }

        button {
            float: right;
            border: none;
            outline: none;
            background-color: transparent;
            height: 32px;
            line-height: 32px;
            color: @fc-main-color;
            cursor: pointer;
        }
    }

    .pre {
        overflow: hidden;

        img, video {
            display: block !important;
            margin: 10px auto !important;
            max-width: 100% !important;
        }
    }
</style>